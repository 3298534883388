import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { addEntity } from '../actions/genericActions';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'subTheme', headerName: 'Sub-Theme', width: 300 },
  { field: 'generalObjectives', headerName: 'GeneralObjectives', width: 300 },
  { field: 'teachingStrategies', headerName: 'Teaching Stategies/Methods', width: 300 },
  { field: 'learningActivities', headerName: 'Learning Activities', width: 300 },
  { field: 'resources', headerName: 'Resources/Technologies', width: 300 },
  { field: 'assessmentProcedures', headerName: 'Assessment Procedures', width: 300 },
  { field: 'followUpActivities', headerName: 'Follow-up Activities', width: 300 },
  

];

/*const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];*/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Language Arts',
  'Mathematics',
  'Social Studies',
  'Science',
  'Art',
  'Music',
  'Relegious Education',
  'Health & Family Life Education',
  'Digital Literacy',
  'Spanish',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const steps = [
  {
    label: 'Step 1: Add Basic Info',
    description: 'Enter the basic information for your web.',
  },
  {
    label: 'Step 2: Add Subjects areas',
    description: 'Select the subjects to be included.',
  },
  {
    label: 'Step 3: Add Objectives/Targets',
    description: 'Select a list of learning targets for each subject area',
  },
  {
    label: 'Step 4: Confirm and Submit',
    description: 'Review the information and submit.',
  },
];

const GenericCrudAdd = ({ entityName }) => {
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState('');
  const [rationale, setRationale] = useState('');
  //const [duration, setDuration] = useState('');
  const [classname, setClassname] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  //const [topics, setTopics] = useState('');
  //const [nameError, setNameError] = useState('');

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  //const [age, setAge] = React.useState('');

  /*const handleAgeChange = (event) => {
    setAge(event.target.value);
  };
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };*/
  const handleSubjectChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (rationale) {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      if (name) {
        // Assuming you want to add more fields to the dispatched data
        dispatch(addEntity(entityName, { title, classname, ageGroup, name }));
        // Clear form fields
        setTitle('');
        setClassname('');
        setAgeGroup('');
        setName('');
        setNameError('');
      } else {
        setNameError('Name is required');
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  //const [inputFields, setInputFields] = useState(['']); // Initial input field
  const [objectives, setObjectives] = useState(['']);
  //const [learningActivities, setLearningActivities] = useState(['']);
  //const [results, setResults] = useState([]); // Array to store results

  /*const addInputField = ():any => {
    setInputFields([...inputFields, '']); // Add a new input field
  };*/
  const addObjectives = ():any => {
    console.log('add objective');
    setObjectives([...objectives,    {
      id: objectives.length+1, // Assign a unique id
      subTheme: '', // Initialize other properties as needed
      objective: '',
      strategies: '',
      activities: '',
      resources: '',
      assessment: '',
      followup: '',
    }]); // Add a new input field
  };
  /*const addActivity = ():any => {
    setLearningActivities([...learningActivities, '']); // Add a new input field
  };

  const handleInputChange = (index, value):any => {
    const newInputFields = [...inputFields];
    newInputFields[index] = value;
    setInputFields(newInputFields);
  };*/

  const handleObjectiveChange = (index, key, value):any => {
    const newInputFields = [...objectives];
    //newInputFields[index][key] = value;
    
    //setObjectives(newInputFields);
    //const newInputFields = [...objectives]; // Assuming objectives is an array of objects
    const updatedObjective = { ...newInputFields[index], id: index+1, [key]: value};
    newInputFields[index] = updatedObjective;
    console.log(newInputFields);
    setObjectives(newInputFields);
  };
  /*const handleActivityChange = (index, value):any => {
    const newInputFields = [...learningActivities];
    newInputFields[index] = value;
    setLearningActivities(newInputFields);
  };*/
  //console.log("OB: "+objectives.length );
  //console.log(JSON.stringify(objectives));
  return (
    <>
    <Box sx={{ '& .MuiTextField-root': { m: 2, width: '45ch' }, display: 'flex',flexDirection: 'row' }} >
     
      <div style={{maxWidth: 500}} >
      <h2>Create Instructional Plan </h2>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {activeStep === index && (
                  <div>
                    {activeStep === 0 && (
                      <div>
                        <TextField
                          required
                          id="outlined-required-title"
                          label="Rationale"
                          value={rationale}
                          onChange={(e) => setRationale(e.target.value)}
                        />             
                                                             
                      </div>
                    )}

                    {activeStep === 1 && (
                      <div>

                         <div>
                              {objectives.map((input, index) => (
                                 <div key={index}>
                                    <Typography variant="h4" gutterBottom>
                                      Lesson {index+1}
                                    </Typography>
                                    <TextField
                                    
                                    label={`Subtheme`}
                                    value={input.subtheme}
                                    onChange={(e) => handleObjectiveChange(index,'subTheme', e.target.value)}
                                    />
                                    <TextField
                                    
                                    label={`General Objective`}
                                    value={input.objective}
                                    onChange={(e) => handleObjectiveChange(index,'generalObjectives', e.target.value)}
                                    />
                                    <TextField
                                    
                                    label={`Teaching Stragtegies`}
                                    value={input.strategies}
                                    onChange={(e) => handleObjectiveChange(index,'teachingStrategies', e.target.value)}
                                    />
                                    <TextField
                                    
                                    label={`Learning Activities`}
                                    value={input.activities}
                                    onChange={(e) => handleObjectiveChange(index,'learningActivities', e.target.value)}
                                    />
                                    <TextField
                                   
                                    label={`Resources`}
                                    value={input.resources}
                                    onChange={(e) => handleObjectiveChange(index,'resources', e.target.value)}
                                    />
                                    <TextField
                                    
                                    label={`Assessment`}
                                    value={input.assessment}
                                    onChange={(e) => handleObjectiveChange(index,'assessmentProcedures', e.target.value)}
                                    />
                                    <TextField
                                    
                                    label={`Follow-up Activities`}
                                    value={input.followup}
                                    onChange={(e) => handleObjectiveChange(index,'followUpActivities', e.target.value)}
                                    />
                                 </div>
                              ))}
                              <Button variant="contained" onClick={addObjectives}>
                                Add 
                              </Button>

                         </div>

                      </div>
                    )}
                    {activeStep === 2 && (
                      <div>

                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={personName}
                            onChange={handleSubjectChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <div>
        <br/><br/><br/><br/>
        <h3>Instructional Plan</h3>
        <Card sx={{ maxWidth: 600 }}>
          <CardContent>
          {objectives.length > 1 && (
            <DataGrid
              rows={objectives}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 },
                },
              }}
              pageSizeOptions={[15, 50, 100]}
              checkboxSelection
              {...(objectives.length > 0 && {
                rows: objectives,
              })}
            />)}
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      </div>
    </Box>
    </>
  );
};

export default GenericCrudAdd;
