/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//import MDTypography from "../MDTypography";
//import MDBox from "../MDBox";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components

import ProfileInfoCard from "../Cards/InfoCards/ProfileInfoCard";
//import ProfilesList from "../List/ProfilesList";
//import DefaultProjectCard from "../Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "./ProfileHeader";
//import PlatformSettings from "../PlatformSettings";

// Data
//import profilesListData from "../../data/profilesListData";

// Images
/*import homeDecor1 from "../../assets/images/home-decor-1.jpg";
import homeDecor2 from "../../assets/images/home-decor-2.jpg";
import homeDecor3 from "../../assets/images/home-decor-3.jpg";
import homeDecor4 from "../../assets/images/home-decor-4.jpeg";
import team1 from "../../assets/images/team-1.jpg";
import team2 from "../../assets/images/team-2.jpg";
import team3 from "../../assets/images/team-3.jpg";
import team4 from "../../assets/images/team-3.jpg";*/

function Student():any {
  return (
    <>      
      <Box mb={2} />
        <Header>
          <Box mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
              <ProfileInfoCard
                  title="profile information"
                  description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={{
                    fullName: "Alec M. Thompson",
                    mobile: "(44) 123 1234 123",
                    email: "alecthompson@mail.com",
                    location: "USA",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  />
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <ProfileInfoCard
                  title="profile information"
                  description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={{
                    fullName: "Alec M. Thompson",
                    mobile: "(44) 123 1234 123",
                    email: "alecthompson@mail.com",
                    location: "USA",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4}>
                {/*<ProfilesList title="conversations" profiles={profilesListData} shadow={false} />*/}
              </Grid>
            </Grid>
          </Box>
          <Box pt={2} px={2} lineHeight={1.25}>
            <Typography variant="h6" fontWeight="medium">
              Projects
            </Typography>
            <Box mb={1}>
              <Typography variant="button" color="text">
                Architects design houses
              </Typography>
            </Box>
          </Box>
          <Box p={2}>
            <Grid container spacing={6}>
                        
              <Grid item xs={12} md={6} xl={3}>
                  jjhjhjhjhjhj
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                gfgfgfgfgf
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                hjhjhj
              </Grid>
            </Grid>
          
          </Box>
        </Header>      
    </>
  );
}

export default Student;
