// webActions.js
export const FETCH_WEB_REQUEST = 'FETCH_WEB_REQUEST';
export const FETCH_WEB_SUCCESS = 'FETCH_WEB_SUCCESS';
export const FETCH_WEB_FAILURE = 'FETCH_WEB_FAILURE';

export const fetchWebRequest = () => ({
  type: FETCH_WEB_REQUEST,
});

export const fetchWebSuccess = (data) => ({
  type: FETCH_WEB_SUCCESS,
  payload: data,
});

export const fetchWebFailure = (error) => ({
  type: FETCH_WEB_FAILURE,
  payload: error,
});
