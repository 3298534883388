import { combineReducers } from 'redux';
import genericReducer from './genericReducer';
import lessonPlanReducer from './lessonPlanReducer';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  students: genericReducer,
  dashboard: dashboardReducer,
  lessonPlans: lessonPlanReducer
});

export default rootReducer;
