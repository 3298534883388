import React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
//import { fetchStudentsRequest, deleteStudentRequest } from '../../actions/genericActions';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DownloadIcon from '@mui/icons-material/Download';
//import TimelineContainer from "../timeline/TimelineContainer.js";
import { connect } from 'react-redux';
//import { fetchWebRequest } from '../actions/webActions';
import { fetchWebRequest } from '../../actions/webActions';
const WebList = ({ entityName}) => {
 
  //const dispatch = useDispatch();
  const students = [];//useSelector(state => state[entityName].data);

  return (

        <div>
          <h2>{entityName} List</h2>
          <Box sx={{  mx: 'auto',minWidth: 275 }}>

            {students.map(student => (
              <Card sx={{ m: 2 }} variant="outlined" key={student.id}>
              <CardContent>
              <Stack direction="row" justifyContent="end" spacing={1}>
                  
                  <IconButton aria-label="add to favorites">
              <DownloadIcon />
             </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
                </Stack>  
               <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
               {student.name} {"Class 1"} {" (7-8yrs old)"}
               </Typography>
              
               
               
               <Typography sx={{ mb: 1.5 }} color="text.secondary">
                 12 Weeks
               </Typography>
               <Typography variant="body2">
                Subjects:
               </Typography>
                 <Stack direction="row" spacing={1}>
                  <Chip label="Language Arts" />
                  <Chip label="Mathematics" />
                  <Chip label="Social Studies" />
                  <Chip label="Science" />
                  <Chip label="Arts" />
                  <Chip label="Religious education" />
                  <Chip label="Health & Family life" />
                  <Chip label="Digital Literacy" />
                 </Stack>
               
             </CardContent>
             <CardActions     disableSpacing
                      sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        }}>
                <Stack direction="row"  spacing={1}>
                  <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.secondary">
                 
                  </Typography>
                  <Chip label="2023-2024 Term 1" />
                  <Chip label="14 Related Thematic webs" variant="outlined" /> 
                  <Chip label="VIEW" variant="outlined" />               
                </Stack>
             </CardActions></Card>
             
            ))}              
            </Box>
          
        </div>

  );
};

const mapStateToProps = (state) => ({
  entityName: 'web', // Assuming 'web' as your entity name
  data: state,
});

const mapDispatchToProps = {
  fetchWebRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebList);
//export default WebList;
