import React from 'react';
//import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider
import CssBaseline from '@mui/material/CssBaseline'; // Optional, adds basic styles

import theme from './styles/theme'; // Import your Material-UI theme (create your theme if you haven't already)
import './styles/main.css';
// Use createRoot instead of ReactDOM.render
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}> {/* Wrap your App with ThemeProvider */}
      <CssBaseline /> {/* Optional, adds basic styles */}
      <App />
    </ThemeProvider>
  </Provider>
);
