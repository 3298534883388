import { Timeline } from "@mui/lab";
import React from "react";
import TimelineCard from "./TimelineCard";

const TimelineContainer = (): any => {
  return (
    <Timeline position="alternate">
      <TimelineCard
        date="Week 1" 
        subject="Week 1"
        topic="Monday"
        lessonTitle=""
        summary=""
        icon="home"
      />
      <TimelineCard 
              date="Monday 9am" 
              subject="Mathematics"
              topic="Fractions"
              lessonTitle="Naming fractions"
              summary="bla bla bla"
              icon="lesson"/>
      <TimelineCard         
        date="9:00" 
        subject="Language Arts"
        topic="Grammar"
        lessonTitle="Nouns"
        summary="yayayay ayyay yayay"
        icon="lesson"/>
      <TimelineCard 
              date="Tuesday 10:00 AM " 
              subject="GGG"
              topic="skjsk"
              lessonTitle="aaaa"
              summary=" sa sa sa s a sa"
              icon="lesson"/>
    </Timeline>
  );
};

export default TimelineContainer;