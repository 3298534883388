// dashboardReducer.js

import {
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_FAILURE,
  FETCH_CURRICULUM_WEB_SUCCESS,
  FETCH_THEMATIC_WEB_SUCCESS,
  FETCH_WEEKLY_PLAN_SUCCESS,
  FETCH_LESSON_PLAN_SUCCESS,
  FETCH_ANALYTICS_SUCCESS,
} from '../actions/dashboardActions';

const initialState = {
  data: {
    curriculumWeb: {},
    thematicWeb: {},
    weeklyPlan: {},
    lessonPlans: {},
    analytics: {},
  },
  isLoading: false,
  error: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_REQUEST:
      return { ...state, isLoading: true, error: null };
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case FETCH_DASHBOARD_DATA_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case FETCH_CURRICULUM_WEB_SUCCESS:
      return { ...state, data: { ...state.data, curriculumWeb: action.payload } };
    case FETCH_THEMATIC_WEB_SUCCESS:
      return { ...state, data: { ...state.data, thematicWeb: action.payload } };
    case FETCH_WEEKLY_PLAN_SUCCESS:
      return { ...state, data: { ...state.data, weeklyPlan: action.payload } };
    case FETCH_LESSON_PLAN_SUCCESS:
      return { ...state, data: { ...state.data, lessonPlans: action.payload } };
    case FETCH_ANALYTICS_SUCCESS:
      return { ...state, data: { ...state.data, analytics: action.payload } };

    default:
      return state;
  }
};

export default dashboardReducer;
