import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchStudentsSuccess, fetchStudentsFailure, addStudentSuccess, addStudentFailure, deleteStudentSuccess, deleteStudentFailure } from '../actions/studentActions';
import { fetchStudentsApi, addStudentApi, deleteStudentApi } from '../api/etl';

function* fetchStudentsSaga() {
    try {
        const response = yield call(fetchStudentsApi);
        yield put(fetchStudentsSuccess(response));
    } catch (error) {
        yield put(fetchStudentsFailure(error.message));
    }
}

function* addStudentSaga(action) {
    try {
        const response = yield call(addStudentApi, action.payload);
        yield put(addStudentSuccess(response));
    } catch (error) {
        yield put(addStudentFailure(error.message));
    }
}

function* deleteStudentSaga(action) {
    try {
        yield call(deleteStudentApi, action.payload);
        yield put(deleteStudentSuccess(action.payload));
    } catch (error) {
        yield put(deleteStudentFailure(error.message));
    }
}

export function* watchFetchStudents() {
    yield takeLatest('FETCH_STUDENTS_REQUEST', fetchStudentsSaga);
}

export function* watchAddStudent() {
    yield takeLatest('ADD_STUDENT_REQUEST', addStudentSaga);
}

export function* watchDeleteStudent() {
    yield takeLatest('DELETE_STUDENT_REQUEST', deleteStudentSaga);
}

export default function* studentSagas() {
    yield all([
        watchFetchStudents(),
        watchAddStudent(),
        watchDeleteStudent(),
    ]);
}
