// reducers/authReducer.js

  import {
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
  } from '../actions/authActions';
  
  const initialState = {
    isAuthenticated: false,
    isRegistering: false,
    registrationError: null,
    user: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case REGISTER_USER_REQUEST:
        console.log('REGISTER_USER_REQUEST');
        console.log(action.payload);
        return {
          ...state,
          isRegistering: true,
          registrationError: null,
        };
      case REGISTER_USER_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          isRegistering: false,
          user: action.payload,
          registrationError: null,
        };
      case REGISTER_USER_FAILURE:
        return {
          ...state,
          isAuthenticated: false,
          isRegistering: false,
          registrationError: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  export default authReducer;
  