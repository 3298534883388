import React from 'react';
import { Box, Container, Grid, Typography, Button, Badge } from '@mui/material';

const events = [
  {
    date: 'January 2025',
    title: 'Beta Release',
    description: 'Edukentia beta version goes live! Selected educators will experience firsthand our AI-powered planning tools designed to save time and enhance classroom experience.',
    badgeColor: '#0d6efd', // Bootstrap's info color
  },
  {
    date: 'April 2025',
    title: 'Collaborative Tools',
    description: 'We expand Edukentia collaboration capabilities. Teachers can easily share resources and interact with students and parents, building a more connected and efficient learning environment.',
    badgeColor: '#198754', // Bootstrap's success color
  },
  {
    date: 'July 2025',
    title: 'Student Insight',
    description: 'This phase introduces tools for tracking student performance, providing real-time insights into academic and behavioral progress enabling more personalized and effective interventions.',
    badgeColor: '#dc3545', // Bootstrap's danger color
  },
  {
    date: 'September 2025',
    title: 'Edukentia Production Release ',
    description: 'Edukentia officially launches, offering full access to our suite of tools.',
    badgeColor: '#ffc107', // Bootstrap's warning color
  },
];

const HorizontalTimeline = () => {
  return (
    <Box sx={{ py: 5, backgroundColor: '#f8f9fa' }}>
      <Container>
        <Box className="horizontal-timeline" sx={{ borderTop: '5px solid #e9ecef', pt: 5 }}>
          <Grid container justifyContent="center" spacing={3}>
            {events.map((event, index) => (
              <Grid item xs={12} md={3} key={index} sx={{ textAlign: 'center' }}>
                <Box className="items-list" sx={{ position: 'relative', pt: { xs: 7, md: 4 } }}>
                  {/* Dotted line above each event */}
                  <Box
                    sx={{
                      content: '""',
                      position: 'absolute',
                      height: '50px',
                      borderRight: '2px dashed #dee2e6',
                      top: -10,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      display: { xs: 'none', md: 'block' },
                    }}
                  />
                  <Box
                    sx={{
                      backgroundColor: event.badgeColor,
                      position: 'absolute',
                      top: { xs: '36px', md: '-80px' },
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '120px',
                      height: '30px',
                      borderRadius: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#fff',
                      fontSize: '0.9rem',
                    }}
                  >
                    {event.date}
                  </Box>
                  <Typography variant="h6" sx={{ pt: 1 }}>
                    {event.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.description}
                  </Typography>
                  {/*<Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mt: 2 }}
                    href="#"
                  >
                    Read more
                  </Button>*/}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default HorizontalTimeline;
