// Example of specific action types for students entity
//const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
//const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
//const FETCH_STUDENTS_FAILURE = 'FETCH_STUDENTS_FAILURE';

const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
const FETCH_STUDENTS_FAILURE = 'FETCH_STUDENTS_FAILURE';
const ADD_STUDENT_REQUEST = 'ADD_STUDENT_REQUEST';
const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
const ADD_STUDENT_FAILURE = 'ADD_STUDENT_FAILURE';
const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST';
const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
const DELETE_STUDENT_FAILURE = 'DELETE_STUDENT_FAILURE';
const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
//const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';
const SIGN_IN_USER_FAILURE = 'SIGN_IN_USER_FAILURE';
const SIGN_IN_USER_SUCCESS = 'SIGN_IN_USER_SUCCESS';
// Specific action creators for students entity
export const fetchEntity = ()  => ({ type: FETCH_STUDENTS_REQUEST });
export const addEntity = ()  => ({ type: FETCH_STUDENTS_REQUEST });

export const registerUserRequest = (userData) => ({
    type: REGISTER_USER_REQUEST,
    payload: userData,
});
export const registerUserFailure = (error)  => ({ type: REGISTER_USER_FAILURE, payload: error });
export const registerUserSuccess = (data)  => ({ type: SIGN_IN_USER_SUCCESS, payload: data });
export const signInUserRequest = (userData) => ({
    type: SIGN_IN_USER_REQUEST,
    payload: userData,
});
export const signInUserFailure = (error)  => ({ type: SIGN_IN_USER_FAILURE, payload: error });
export const signInUserSuccess = (data)  => ({ type: SIGN_IN_USER_SUCCESS, payload: data });

export const fetchStudentsRequest = ()  => ({ type: FETCH_STUDENTS_REQUEST });
export const fetchStudentsSuccess = (data)  => ({ type: FETCH_STUDENTS_SUCCESS, payload: data });
export const fetchStudentsFailure = (error)  => ({ type: FETCH_STUDENTS_FAILURE, payload: error });

export const addStudentRequest = (studentData)  => ({ type: ADD_STUDENT_REQUEST, payload: studentData });
export const addStudentSuccess = (student)  => ({ type: ADD_STUDENT_SUCCESS, payload: student });
export const addStudentFailure = (error)  => ({ type: ADD_STUDENT_FAILURE, payload: error });

export const deleteStudentRequest = (studentId)  => ({ type: DELETE_STUDENT_REQUEST, payload: studentId });
export const deleteStudentSuccess = (studentId)  => ({ type: DELETE_STUDENT_SUCCESS, payload: studentId });
export const deleteStudentFailure = (error)  => ({ type: DELETE_STUDENT_FAILURE, payload: error });
