// src/styles/theme.js
//import { createTheme } from '@mui/material/styles';

/*const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff', // Your primary color
    },
    secondary: {
      main: '#ff6f61', // Your secondary color
    },
  },
});*/
import  createMuiTheme  from '@mui/material/styles/createMuiStrictModeTheme';

const theme = createMuiTheme();
export default theme;
