import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchSchoolsSuccess,
  fetchSchoolsFailure,
  createSchoolSuccess,
  createSchoolFailure,
  updateSchoolSuccess,
  updateSchoolFailure,
  deleteSchoolSuccess,
  deleteSchoolFailure,
} from '../actions/schoolActions';
import {
  fetchSchoolsApi,
  createSchoolApi,
  updateSchoolApi,
  deleteSchoolApi,
} from '../api/schoolApi';

function* fetchSchoolsSaga() {
  try {
    const response = yield call(fetchSchoolsApi);
    yield put(fetchSchoolsSuccess(response));
  } catch (error) {
    yield put(fetchSchoolsFailure(error.message));
  }
}

function* createSchoolSaga(action) {
  try {
    const response = yield call(createSchoolApi, action.payload);
    yield put(createSchoolSuccess(response));
  } catch (error) {
    yield put(createSchoolFailure(error.message));
  }
}

function* updateSchoolSaga(action) {
  try {
    const response = yield call(updateSchoolApi, action.payload.schoolId, action.payload.schoolData);
    yield put(updateSchoolSuccess(response));
  } catch (error) {
    yield put(updateSchoolFailure(error.message));
  }
}

function* deleteSchoolSaga(action) {
  try {
    yield call(deleteSchoolApi, action.payload);
    yield put(deleteSchoolSuccess(action.payload));
  } catch (error) {
    yield put(deleteSchoolFailure(error.message));
  }
}

export default function* schoolActions() {
  yield takeLatest('FETCH_SCHOOLS_REQUEST', fetchSchoolsSaga);
  yield takeLatest('CREATE_SCHOOL_REQUEST', createSchoolSaga);
  yield takeLatest('UPDATE_SCHOOL_REQUEST', updateSchoolSaga);
  yield takeLatest('DELETE_SCHOOL_REQUEST', deleteSchoolSaga);
}
