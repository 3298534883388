// Action Types
export const FETCH_SCHOOLS_REQUEST = 'FETCH_SCHOOLS_REQUEST';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';

export const CREATE_SCHOOL_REQUEST = 'CREATE_SCHOOL_REQUEST';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAILURE = 'CREATE_SCHOOL_FAILURE';

export const UPDATE_SCHOOL_REQUEST = 'UPDATE_SCHOOL_REQUEST';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_FAILURE = 'UPDATE_SCHOOL_FAILURE';

export const DELETE_SCHOOL_REQUEST = 'DELETE_SCHOOL_REQUEST';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_FAILURE = 'DELETE_SCHOOL_FAILURE';

// Action Creators

// Fetch Schools
export const fetchSchoolsRequest = () => ({
    type: FETCH_SCHOOLS_REQUEST,
});

export const fetchSchoolsSuccess = (schools) => ({
    type: FETCH_SCHOOLS_SUCCESS,
    payload: schools,
});

export const fetchSchoolsFailure = (error) => ({
    type: FETCH_SCHOOLS_FAILURE,
    payload: error,
});

// Create School
export const createSchoolRequest = (schoolData) => ({
    type: CREATE_SCHOOL_REQUEST,
    payload: schoolData,
});

export const createSchoolSuccess = (school) => ({
    type: CREATE_SCHOOL_SUCCESS,
    payload: school,
});

export const createSchoolFailure = (error) => ({
    type: CREATE_SCHOOL_FAILURE,
    payload: error,
});

// Update School
export const updateSchoolRequest = (schoolId, schoolData) => ({
    type: UPDATE_SCHOOL_REQUEST,
    payload: { schoolId, schoolData },
});

export const updateSchoolSuccess = (school) => ({
    type: UPDATE_SCHOOL_SUCCESS,
    payload: school,
});

export const updateSchoolFailure = (error) => ({
    type: UPDATE_SCHOOL_FAILURE,
    payload: error,
});

// Delete School
export const deleteSchoolRequest = (schoolId) => ({
    type: DELETE_SCHOOL_REQUEST,
    payload: schoolId,
});

export const deleteSchoolSuccess = (schoolId) => ({
    type: DELETE_SCHOOL_SUCCESS,
    payload: schoolId,
});

export const deleteSchoolFailure = (error) => ({
    type: DELETE_SCHOOL_FAILURE,
    payload: error,
});
