import { put, takeLatest, call, all } from 'redux-saga/effects';
import { 
    registerUserRequest,
    registerUserSuccess, 
    registerUserFailure, 
    signInUserSuccess, 
    signInUserFailure 
} from '../actions/authActions';
import { 
    registerUser, 
    signInUser 
} from '../api/etl';

function* registerUserSaga(action) {
    console.log(action.payload);
    try {
        const response = yield call(registerUser, action.payload);
        yield put(registerUserSuccess(response));
    } catch (error) {
        yield put(registerUserFailure(error.message));
    }
}

function* signInUserSaga(action) {
    try {
        const response = yield call(signInUser, action.payload);
        yield put(signInUserSuccess(response));
    } catch (error) {
        yield put(signInUserFailure(error.message));
    }
}

export function* watchRegisterUser() {
    console.log("watching!!!");
    yield takeLatest('REGISTER_USER_REQUEST', registerUserSaga);
}

export function* watchSignInUser() {
    yield takeLatest('SIGN_IN_USER_REQUEST', signInUserSaga);
}

export default function* authSagas() {
    yield all([
        watchRegisterUser(),
        watchSignInUser(),
    ]);
}
