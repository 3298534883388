import {
    FETCH_LESSON_PLANS_REQUEST,
    FETCH_LESSON_PLANS_SUCCESS,
    FETCH_LESSON_PLANS_FAILURE,
    CREATE_LESSON_PLAN_REQUEST,
    CREATE_LESSON_PLAN_SUCCESS,
    CREATE_LESSON_PLAN_FAILURE
} from '../actions/lessonPlanActions';

import {
    FETCH_LESSON_PLAN_DETAIL_REQUEST,
    FETCH_LESSON_PLAN_DETAIL_SUCCESS,
    FETCH_LESSON_PLAN_DETAIL_FAILURE,
} from '../actions/lessonPlanActions';

const initialState = {
    lessonPlans: [],
    lessonPlanDetail: null,
    isLoading: false,
    error: null,
};

const lessonPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LESSON_PLAN_DETAIL_REQUEST:
            return { ...state, isLoading: true, error: null };
        
        case FETCH_LESSON_PLAN_DETAIL_SUCCESS:
            return { ...state, lessonPlanDetail: action.payload, isLoading: false };
        
        case FETCH_LESSON_PLAN_DETAIL_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        
        // Other cases...

        default:
            return state;
    }
};

export default lessonPlanReducer;

