import * as React from 'react';
//import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MultiActionAreaCard from "./MultiActionAreaCard";

/*const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));*/

export default function ResponsiveGrid({items}) {
  const cols = items?items: 4
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 3, sm: 8, md: 12 }}>
        {Array.from(Array(cols)).map((_, index) => (
          <Grid item xs={2} sm={3} md={3} key={index}>
            <MultiActionAreaCard/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}