//import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import TimelineContainer from '../timeline/TimelineContainer.js';

const WeeklyPlans = ({}): any => {

  return (

        <div>
          <h2>Weekly Plans</h2>
          <Box sx={{  mx: 'auto',minWidth: 275 }}>
            <TimelineContainer  />   
             
          </Box>          
        </div>

  );
};

export default WeeklyPlans;
