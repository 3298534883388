import React from 'react';
import { Paper, List, ListItem, ListItemIcon, ListItemText, Container, Typography, Button, Grid, Card, CardMedia, CardContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Footer from './LandingPage/Footer';
import Header from './LandingPage/Header';
import About from './LandingPage/About';
import FeatureSection from './LandingPage/FeatureSection';
import HorizontalTimeline from './LandingPage/HorizontalTimeline';
import JoinNewsletter from './LandingPage/JoinNewsletter';
import HeroSection from './LandingPage/HeroSection';
import SDG4Alignment from './LandingPage/SDG4Alignment';

const LandingPage = ({ isLoggedIn }) => {
  return (
    <div>
      <Header isLoggedIn={isLoggedIn} />
      {/* Hero Section */}
      <section style={{ display:'none',backgroundImage:'url("./images/page-turner-gr.svg")',backgroundPosition: 'left top',backgroundRepeat: 'no-repeat', padding: '80px 0' }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{backgroundImage:'url("./images/scattered-forcefields_bg.svg")', backgroundPosition: 'right top',backgroundRepeat: 'no-repeat',minHeight: '80vh', textAlign: 'center' }}>
          
          <Grid item xs={12} md={8}>
            <Typography variant="h2" sx={{ margin: '20px 0' }} gutterBottom>
              Empowered Learning For Everyone
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
              Transform your classroom experience, save time, and enhance student outcomes.
            </Typography>
            {!isLoggedIn && (
              <Button variant="contained" color="primary" size="large" href="/register">
                Get Started
              </Button>
            )}
          </Grid>
        </Grid>
      </section>
      <section>
            <HeroSection/>
      </section>
      {/* Conditional Content */}
      {isLoggedIn ? (
        <section style={{ padding: '80px 0', backgroundColor: '#ffffff' }}>
          <Container>
            <Typography variant="h4" align="center" color="primary" gutterBottom>
              Welcome Back!
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Explore new resources and manage your dashboard efficiently.
            </Typography>
          </Container>
        </section>
      ) : (
        <>
        <About />
        <section style={{ padding: '80px 0', backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
          <Container>
            <Typography variant="h4" align="center" color="primary" gutterBottom>
              Why Edukentia?
            </Typography>
            {/* Benefits Section */}
            <FeatureSection/>
          </Container>
        </section>
        <section style={{ padding: '80px 0', backgroundColor: '#ffffff', backgroundImage:'url("./images/scattered-forcefields_cg.svg")', backgroundSize: 'cover', backgroundPosition: 'right top',backgroundRepeat: 'no-repeat' }}>
              <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ flex: 1 }}>
                  <Typography variant="h4" align="center" color="primary" gutterBottom>
                    See It In Action
                  </Typography>
                  <Typography variant="h5" align="center" color="textSecondary" paragraph>
                  Experience the Future of Instructional Planning with Educore.
                </Typography>
                </div>
        
                {/* Bordered box with YouTube video */}
                <Paper
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    border: '2px solid #dddddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.5), -8px 0px 16px -8px rgba(0, 0, 0, 0.5)',
                    margin: '20px 0',
                  }}
                >
                  {/* Embed YouTube video using an iframe */}
                  <iframe
                    title="EduMaster Demo"
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/your-youtube-video-id"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </Paper>
              </Container>
        </section>
        <section>
          <SDG4Alignment/>
        </section>
        </>
      )}
      
      <section style={{ padding: '50px 0', backgroundColor: '#f8f9fa' }}>
          <Container>
            <Typography variant="h4" align="center" color="primary" gutterBottom>
            Our Roadmap To Success
            </Typography>
            <HorizontalTimeline/>
          </Container>
        </section>
        <section>         
            <JoinNewsletter/>          
        </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
