import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEntity } from '../actions/genericActions';

const GenericCrudDetail = ({ entityName, entityId }): any => {
  const dispatch = useDispatch();
  const entity = useSelector(state => state[entityName].data.find(e => e.id === entityId));
  const isLoading = useSelector(state => state[entityName].isLoading);
  const error = useSelector(state => state[entityName].error);

  useEffect(() => {
    dispatch(fetchEntity(entityName, entityId));
  }, [dispatch, entityName, entityId]);

  if (!entity) {
    return <div>Entity not found.</div>;
  }

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <div>
          <h2>{entityName} Details</h2>
          <p><strong>Name:</strong> {entity.name}</p>
          {/* Add other entity details */}
        </div>
      )}
    </div>
  );
};

export default GenericCrudDetail;
