// authActions.js

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';
export const SIGN_IN_USER_SUCCESS = 'SIGN_IN_USER_SUCCESS';
export const SIGN_IN_USER_FAILURE = 'SIGN_IN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

// Action Creators
export const registerUserRequest = (userData) => ({
    type: REGISTER_USER_REQUEST,
    payload: userData
});

export const registerUserSuccess = (data) => ({
    type: REGISTER_USER_SUCCESS,
    payload: data
});

export const registerUserFailure = (error) => ({
    type: REGISTER_USER_FAILURE,
    payload: error
});

export const signInUserRequest = (credentials) => ({
    type: SIGN_IN_USER_REQUEST,
    payload: credentials
});

export const signInUserSuccess = (data) => ({
    type: SIGN_IN_USER_SUCCESS,
    payload: data
});

export const signInUserFailure = (error) => ({
    type: SIGN_IN_USER_FAILURE,
    payload: error
});

export const logoutUser = () => ({
    type: LOGOUT_USER
});
