import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { registerUserRequest } from '../actions/authActions';
import { useNavigate } from 'react-router-dom';
//import config from './config/app';
import config from '../config/app';
// Define API version prefix
const api = config.version;//"/v1"; 

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://soshinemedia.com/">
        SoShine Media
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function isValidPassword(password) {
  return password.length >= 8;
}

const defaultTheme = createTheme();

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, isRegistering, registrationError } = useSelector(state => state.auth);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    role: 'teacher',
    password: '',
    agreeToTerms: false,
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate(api+'/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const isFormValid = formData.first_name.trim() !== '' &&
    formData.last_name.trim() !== '' &&
    isValidEmail(formData.email) &&
    isValidPassword(formData.password) &&
    formData.agreeToTerms;

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
      username: name === 'email' ? value : prevData.username,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Dispatching registerUserRequest'); // Debugging line
        
    if (isFormValid) {
      dispatch(registerUserRequest(formData));
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="first_name"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  onChange={handleInputChange}
                  value={formData.first_name}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="family-name"
                  onChange={handleInputChange}
                  value={formData.last_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!isValidEmail(formData.email) && formData.email !== ''}
                  helperText={!isValidEmail(formData.email) && formData.email !== '' && 'Invalid email address'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={!isValidPassword(formData.password) && formData.password !== ''}
                  helperText={!isValidPassword(formData.password) && formData.password !== '' && 'Password is too short'}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeToTerms"
                      checked={formData.agreeToTerms}
                      onChange={handleInputChange}
                      color="primary"
                      required
                    />
                  }
                  label="I agree to the terms and conditions"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
                By clicking “Sign up”, you agree to our <Link href="/terms" target="_blank" rel="noopener noreferrer">terms of service</Link> and acknowledge you have read our <Link href="/privacy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.
              </Typography>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isRegistering || !isFormValid}
            >
              {isRegistering ? 'Signing Up...' : 'Sign Up'}
            </Button>

            {registrationError && (
              <Typography color="error" variant="body2">
                Oops! {registrationError.message || registrationError.toString()}
              </Typography>
            )}

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
