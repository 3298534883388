import React, { useEffect } from 'react';
import { Alert, CircularProgress, Container, Grid, Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardDataRequest } from '../actions/dashboardActions';
import DismissibleAlert from './parts/DismissibleAlert';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.dashboard);

  useEffect(() => {
    const userId = localStorage.getItem('userId'); // Fetch user ID (assumed to be stored)
    dispatch(fetchDashboardDataRequest(userId));
  }, [dispatch]);

  if (isLoading) {
    return <CircularProgress />;
  }



  return (
    <>
    {error && <DismissibleAlert severity="error" message={`Error: ${error}`} />}
    <Container>
      <Typography variant="h4" gutterBottom align="center" sx={{ mt: 3 }}>
        Dashboard Overview
      </Typography>
      <Grid container spacing={3}>
        {/* Students Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Students</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Students: {data.studentsCount || 'N/A'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Active: {data.activeStudentsCount || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/students'}>View Details</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Curriculum Web Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Curriculum Web</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Curriculum Webs: {data.curriculumWeb?.length || 'N/A'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Last Updated: {data.curriculumWeb?.[0]?.updated_at || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/curriculum-web'}>View Curriculum Webs</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Lesson Plans Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Lesson Plans</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Plans: {data.lessonPlansCount || 'N/A'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Upcoming: {data.upcomingLessonPlansCount || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/lesson-plans'}>View Lesson Plans</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Weekly Plan Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Weekly Plans</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Weekly Plans: {data.weeklyPlan?.length || 'N/A'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Latest Week: {data.weeklyPlan?.[0]?.week || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/weekly-plans'}>View Weekly Plans</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Analytics Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Analytics</Typography>
              <Typography variant="body2" color="textSecondary">
                Overall Progress: {data.analytics?.overallProgress || 'N/A'}%
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Most Improved Student: {data.analytics?.mostImprovedStudent || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/analytics'}>View Analytics</Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Resources Summary Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Resources</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Resources: {data.resourcesCount || 'N/A'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Recently Added: {data.recentResourcesCount || 'N/A'}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => window.location.href = '/resources'}>View Resources</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default Dashboard;
