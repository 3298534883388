// Terms.js

import React from 'react';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';
const Terms = () => {
  return (
    <Paper elevation={3} style={{ padding: '25px', margin: 'auto', maxWidth: '900px' }}>
      
      <Typography variant="h4" gutterBottom>
        Edukentia Terms of Service
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography paragraph>
        Welcome to Edukentia! By accessing or using Edukentia (the &quot;Platform&quot;),
        you agree to comply with and be bound by the following terms and
        conditions (&quot;Terms&quot;). If you do not agree to these Terms, please
        refrain from using the Platform.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Use of the Platform
      </Typography>
      <ol>
        <li>
          <Typography paragraph>
            <strong>Eligibility:</strong> You must be at least 18 years old to
            use Edukentia. By using the Platform, you represent and warrant
            that you are of legal age.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            <strong>Account Registration:</strong> Users may be required to
            create an account. You are responsible for maintaining the
            confidentiality of your account information and agree to accept
            responsibility for all activities that occur under your account.
          </Typography>
        </li>
        <li>
          <Typography paragraph>
            <strong>User Content:</strong> Users may contribute content to
            Edukentia. By submitting content, you grant Edukentia a
            non-exclusive, worldwide, royalty-free, sublicensable, and
            transferable license to use, reproduce, distribute, prepare
            derivative works of, display, and perform the submitted content.
          </Typography>
        </li>
      </ol>

      {/* Additional sections can be added similarly */}

      <Typography variant="h6" gutterBottom>
        9. Governing Law
      </Typography>
      <Typography paragraph>
        These Terms are governed by and construed in accordance with the laws of
        Barbados. Any disputes arising under or in connection with these Terms
        shall be subject to the exclusive jurisdiction of the courts located
        within Barbados.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Contact Information
      </Typography>
      <Typography paragraph>
        If you have any questions or concerns regarding these Terms, please
        contact us at{' '}
        <Link href="mailto:contact@edukentia.com">contact@edukentia.com</Link>.
      </Typography>
    </Paper>
  );
};

export default Terms;
