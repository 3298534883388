import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RedeemIcon from '@mui/icons-material/Redeem';

import InsightsIcon from '@mui/icons-material/Insights';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupIcon from '@mui/icons-material/Group';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import deviceMockupImage from '../../assets/teacher.jpg'; // Update the path to your mockup image
import OverlappingCirclesWithImage from './OverlappingCirclesWithimages';

const FeatureSection = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 4 }} >
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Device Mockup */}
                <Grid item xs={12} md={4} display="flex" justifyContent="center">
                    <OverlappingCirclesWithImage imageUrl={deviceMockupImage} />
                </Grid>

                {/* Features Section */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4}>
                        {/* Feature 1 */}
                        <Grid item xs={12} sm={6}>
                            <Card     sx={{
                                            height: '100%',
                                            backgroundColor: 'grey.100',
                                            borderRadius: 2,
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': { transform: 'scale(1.05)' },
                                        }} elevation={3}>
                                <CardContent>
                                    <EventNoteIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Intuitive Planning Tools
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Easily create lesson plans and manage instructional materials.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Feature 2 */}
                        <Grid item xs={12} sm={6}>
                        <Card
                        sx={{
                            height: '100%',
                            backgroundColor: 'grey.100',
                            borderRadius: 2,
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': { transform: 'scale(1.05)' },
                        }}
                        elevation={3}
                        >
                                <CardContent>
                                    <InsightsIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Data-Driven Insights
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Leverage analytics to track student progress and optimize teaching strategies.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Feature 3 */}
                        <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                height: '100%',
                                backgroundColor: 'grey.100',
                                borderRadius: 2,
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': { transform: 'scale(1.05)' },
                            }}
                            elevation={3}
                            >
                                <CardContent>
                                    <GroupIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Collaborative Learning Experiences
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Foster collaboration among educators, parents, and students for a holistic learning experience
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Feature 4 */}
                        <Grid item xs={12} sm={6}>
                        <Card
                        sx={{
                            height: '100%',
                            backgroundColor: 'grey.100',
                            borderRadius: 2,
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': { transform: 'scale(1.05)' },
                        }}
                        elevation={3}
                        >
                                <CardContent>
                                    <SyncAltIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" component="div" gutterBottom>
                                        Flexibility
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        The platform facilitates offline recording of data such as register and scheme of work with upload/download template.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </Container>
    );
};

export default FeatureSection;
