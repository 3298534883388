import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchLessonPlansSuccess, fetchLessonPlansFailure, createLessonPlanSuccess, createLessonPlanFailure } from '../actions/lessonPlanActions';
import { fetchLessonPlansApi, createLessonPlanApi } from '../api/etl';

function* fetchLessonPlansSaga() {
    try {
        const response = yield call(fetchLessonPlansApi);
        yield put(fetchLessonPlansSuccess(response));
    } catch (error) {
        yield put(fetchLessonPlansFailure(error.message));
    }
}

function* createLessonPlanSaga(action) {
    try {
        const response = yield call(createLessonPlanApi, action.payload);
        yield put(createLessonPlanSuccess(response));
    } catch (error) {
        yield put(createLessonPlanFailure(error.message));
    }
}

function* watchFetchLessonPlans() {
    yield takeLatest('FETCH_LESSON_PLANS_REQUEST', fetchLessonPlansSaga);
}

function* watchCreateLessonPlan() {
    yield takeLatest('CREATE_LESSON_PLAN_REQUEST', createLessonPlanSaga);
}

export default function* lessonPlanSagas() {
    yield all([
        watchFetchLessonPlans(),
        watchCreateLessonPlan(),
    ]);
}
