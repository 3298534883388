import React from 'react';
//import  makeStyles from '@mui/material/styles/makeStyles';
//import { makeStyles } from '@mui/material/styles';
//import OutlinedInput from '@mui/material/OutlinedInput';
//import { Link } from ReactDOM;
import { Link } from 'react-router-dom';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText, CssBaseline } from '@mui/material';
//import {LibraryBooks as LibraryBooksIcon, Assignment as AssignmentIcon, School as SchoolIcon, Timeline as TimelineIcon, Web as WebIcon, VerifiedUser as UserIcon, Home as HomeIcon, Dashboard as DashboardIcon, Settings as SettingsIcon } from '@mui/icons';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import TimelineIcon from '@mui/icons-material/Timeline';
import WebIcon from '@mui/icons-material/Web';
//import UserIcon from '@mui/icons-material/VerifiedUser';
import HomeIcon from '@mui/icons-material/Home';
//import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';

import NavBar from '../NavBar'; // Adjust the path
import { Outlet } from 'react-router-dom';
//import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

//import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';

//const drawerWidth = 240;

/*const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 999,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop:50,
  },
}));*/

const CrudLayout = () => {
  const classes = useStyles();
  console.log("###DashBoard Layout###");
  const [open, setOpen] = React.useState(false);
  /*const toggleDrawer = () => {
    setOpen(!open);
  };*/
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionClick = (option) => {
    // Handle the option click here
    // You can perform different actions based on the selected option
    console.log(`Option clicked: ${option}`);
  };

  const actions = [
    { icon: <EditIcon />, name: 'Edit' },
    { icon: <DeleteIcon />, name: 'Delete' },
    { icon: <SaveIcon />, name: 'Save' },
  ];
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <NavBar />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
      >

        <div className={classes.drawerContainer}>
        <Toolbar />
          <List>
            <Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem button>
              <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>
           
              <ListItem button>
                <ListItemIcon><SchoolIcon /></ListItemIcon>
                <ListItemText primary="Students" />
              </ListItem>
            

            <Link to="/lesson-plans">
              <ListItem button>
                <ListItemIcon><AssignmentIcon /></ListItemIcon>
                <ListItemText primary="Lesson Plans" />
              </ListItem>
            </Link>

            <Link to="/weekly-plans">
              <ListItem button>
                <ListItemIcon><AssignmentIcon /></ListItemIcon>
                <ListItemText primary="Weekly plans" />
              </ListItem>
            </Link>

            <Link to="/journal">
              <ListItem button>
                <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                <ListItemText primary="Journal" />
              </ListItem>
            </Link>

            <Link to="/webs">
              <ListItem button>
                <ListItemIcon><WebIcon /></ListItemIcon>
                <ListItemText primary="Webs" />
              </ListItem>
            </Link>

            <Link to="/analytics">
              <ListItem button>
                <ListItemIcon><TimelineIcon /></ListItemIcon>
                <ListItemText primary="Analytics" />
              </ListItem>
            </Link>

            <Link to="/settings">
              <ListItem button>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </Link>

          </List>
        </div>
      </Drawer>
      <Toolbar />
      <main className={classes.content}>
        
        <Outlet/>
          
      </main>
      <SpeedDial
        sx={{ position: "relative", top: 140, right: 45 }}
            
        ariaLabel="SpeedDial example"
        icon={<SpeedDialIcon icon={<AddIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up" // Change to 'down' or 'left' for different directions
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleOptionClick(action.name)}
          />
        ))}
      </SpeedDial>

    </div>
  );
};

export default CrudLayout;
