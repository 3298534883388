import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import studentSagas from './studentSagas';
import lessonPlanSagas from './lessonPlanSagas';
import schoolSagas from './schoolSagas';
import timetableSagas from './timetableSagas';
import { dashboardSagas } from './dashboardSagas';

export default function* rootSaga() {
    yield all([
        authSagas(),
        studentSagas(),
        lessonPlanSagas(),
        schoolSagas(),
        timetableSagas(),
        dashboardSagas()
    ]);
}
