// dashboardSagas.js

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_DASHBOARD_DATA_REQUEST,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
  fetchCurriculumWebSuccess,
  fetchThematicWebSuccess,
  fetchWeeklyPlanSuccess,
  fetchLessonPlanSuccess,
  fetchAnalyticsSuccess,
} from '../actions/dashboardActions';
import {
  fetchCurriculumWebApi,
  fetchThematicWebApi,
  fetchWeeklyPlanApi,
  fetchLessonPlansApi,
  fetchAnalyticsApi,
} from '../api/etl';

function* fetchDashboardDataSaga(action) {
  const { payload: userId } = action;
  try {
    const curriculumWeb = yield call(fetchCurriculumWebApi, userId);
    yield put(fetchCurriculumWebSuccess(curriculumWeb));

    const thematicWeb = yield call(fetchThematicWebApi, curriculumWeb.id);
    yield put(fetchThematicWebSuccess(thematicWeb));

    const weeklyPlan = yield call(fetchWeeklyPlanApi, userId);
    yield put(fetchWeeklyPlanSuccess(weeklyPlan));

    const lessonPlans = yield call(fetchLessonPlansApi, userId);
    yield put(fetchLessonPlanSuccess(lessonPlans));

    const analytics = yield call(fetchAnalyticsApi, userId);
    yield put(fetchAnalyticsSuccess(analytics));

    yield put(fetchDashboardDataSuccess({ curriculumWeb, thematicWeb, weeklyPlan, lessonPlans, analytics }));
  } catch (error) {
    yield put(fetchDashboardDataFailure(error.message));
  }
}

export function* dashboardSagas() {
  yield takeLatest(FETCH_DASHBOARD_DATA_REQUEST, fetchDashboardDataSaga);
}
