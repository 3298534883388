import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import config from '../../config/app';
import logo from '../../assets/logo.png'; // Import your logo

const Header = ({ isLoggedIn }) => {
  return (
    <AppBar position="sticky" style={{ zIndex: 1000, backgroundColor: '#fff', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Logo and App Name */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '4em', marginRight: '10px' }} />
          {/*<Typography variant="h4" component="div" sx={{ flexGrow: 1, color: '#333' }}>
            {config.appName}
          </Typography>*/}
        </Box>

        {/* Conditional Navigation Based on Login Status */}
        <Box>
          {isLoggedIn ? (
            <>
              <Button variant="text" color="primary" component={Link} to="/v1/dashboard">Dashboard</Button>
              <Button variant="text" color="primary" component={Link} to="/logout">Logout</Button>
            </>
          ) : (
            <>
              <Button variant="text" color="primary" component={Link} to="/v1/dashboard">Login</Button>
              <Button variant="text" color="primary" component={Link} to="/register">Sign Up</Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
