
// dashboardActions.js

export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_FAILURE = 'FETCH_DASHBOARD_DATA_FAILURE';

export const FETCH_CURRICULUM_WEB_SUCCESS = 'FETCH_CURRICULUM_WEB_SUCCESS';
export const FETCH_THEMATIC_WEB_SUCCESS = 'FETCH_THEMATIC_WEB_SUCCESS';
export const FETCH_WEEKLY_PLAN_SUCCESS = 'FETCH_WEEKLY_PLAN_SUCCESS';
export const FETCH_LESSON_PLAN_SUCCESS = 'FETCH_LESSON_PLAN_SUCCESS';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';

export const fetchDashboardDataRequest = (userId) => ({
  type: FETCH_DASHBOARD_DATA_REQUEST,
  payload: userId,
});

export const fetchDashboardDataSuccess = (data) => ({
  type: FETCH_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDashboardDataFailure = (error) => ({
  type: FETCH_DASHBOARD_DATA_FAILURE,
  payload: error,
});

export const fetchCurriculumWebSuccess = (data) => ({
  type: FETCH_CURRICULUM_WEB_SUCCESS,
  payload: data,
});

export const fetchThematicWebSuccess = (data) => ({
  type: FETCH_THEMATIC_WEB_SUCCESS,
  payload: data,
});

export const fetchWeeklyPlanSuccess = (data) => ({
  type: FETCH_WEEKLY_PLAN_SUCCESS,
  payload: data,
});

export const fetchLessonPlanSuccess = (data) => ({
  type: FETCH_LESSON_PLAN_SUCCESS,
  payload: data,
});

export const fetchAnalyticsSuccess = (data) => ({
  type: FETCH_ANALYTICS_SUCCESS,
  payload: data,
});


