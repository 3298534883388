import React from 'react';
import { Typography, Container, Grid, Link } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#333', color: '#fff', padding: '20px 0', marginTop: '50px' }}>
      <Container>
        <Grid container spacing={1} justifyContent="center" style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1} justifyContent="center">

              <Grid item xs={6}>
                <Link href="/about" color="inherit" underline="hover">About</Link>
              </Grid>
              <Grid item xs={6}>
                <Link href="/services" color="inherit" underline="hover">Services</Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="/contact" color="inherit" underline="hover">Contact</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" align="left">
              &copy; {(new Date().getFullYear())} Edukentia. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Link href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" color="inherit">
                  <Facebook />
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" color="inherit">
                  <Twitter />
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" color="inherit">
                  <Instagram />
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" color="inherit">
                  <LinkedIn />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
