// DismissibleAlert.js
import React, { useState } from 'react';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DismissibleAlert = ({ severity, message, onClose, showInitially = true }) => {
  const [visible, setVisible] = useState(showInitially);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose();  // Optional external callback
  };

  return (
    visible && (
      <Alert
        severity={severity}
        onClose={handleClose}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    )
  );
};

export default DismissibleAlert;
