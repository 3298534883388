import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { useSelector } from 'react-redux';

// Layout Components
import DashboardLayout from './components/layouts/DashboardLayout';
import CrudLayout from './components/layouts/CrudLayout';
import Layout from './components/layouts/Layout';

// Page Components
import Student from './components/crud/Student';
import Dashboard from './components/Dashboard'; 
import GenericCrudList from './components/GenericCrudList'; 
import GenericCrudAdd from './components/GenericCrudAdd'; 
import GenericCrudDetail from './components/GenericCrudDetail'; 
import LandingPage from './components/LandingPage';
import LessonPlanList from './components/crud/LessonPlanList';
import ResourceList from './components/crud/ResourceList';
import WeeklyPlans from './components/crud/WeeklyPlan';
import Journal from './components/crud/Journal';
import WebList from './components/crud/WebList';
import SignUp from './components/SignUp';
import SignInSide from './components/SignInSide';
import Terms from './components/Terms';
import Privacy from './components/PrivacyPolicy';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute component
import config from './config/app';
// Define API version prefix
const api = config.version;//"/v1"; 

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" index element={<LandingPage />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/signin" element={<SignInSide />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        {/* Protected Routes */}
        <Route path={api} element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
          <Route path="students" element={<Student />} />
          <Route path="students/:id" element={<Student />} />
          <Route path="lessons" element={<LessonPlanList entityName="Lessons" />} />
          <Route path="resources" element={<ResourceList entityName="Resources" />} />
          <Route path="resources/:id" element={<ResourceList entityName="Resources" />} />
          <Route path="journal" element={<Journal entityName="Journal" />} />
          <Route path="schedule" element={<WeeklyPlans entityName="Weekly Plans" />} />
          <Route path="webs" element={<WebList entityName="Webs" />} />
          <Route path="add" element={<GenericCrudAdd entityName="Students" />} />
        </Route>

        <Route path={api} element={<PrivateRoute><CrudLayout /></PrivateRoute>}>
          <Route path="webs/crud" element={<GenericCrudList entityName="Webs" />} />
          <Route path="web" element={<DashboardLayout />} />
          <Route path="web/:id" element={<GenericCrudList />} />
          <Route path="add" element={<GenericCrudAdd entityName="Students" />} />
          <Route path="createcweb" element={<GenericCrudAdd entityName="Curriculum Web" />} />
          <Route path="createtweb" element={<GenericCrudAdd entityName="Thematic Web" />} />
          <Route path="createiplan" element={<GenericCrudAdd entityName="Instructional Plan" />} />
          <Route path="createwplan" element={<GenericCrudAdd entityName="Weekly Plan" />} />
          <Route path="createlplan" element={<GenericCrudAdd entityName="Lesson Plan" />} />
          <Route path="display" element={<GenericCrudDetail entityName="Students" />} />
        </Route>

        <Route path={api} element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
       
        {/* Additional routes */}
      </Routes>
    </Router>
  );
}

export default App;
