import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
//import { fetchStudentsRequest, deleteStudentRequest } from '../actions/genericActions';
import { fetchStudentsRequest } from '../../actions/genericActions';
//import Box from '@mui/material/Box';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import FavoriteIcon from '@mui/icons-material/Favorite';
//import ShareIcon from '@mui/icons-material/Share';
//import IconButton, { IconButtonProps } from '@mui/material/IconButton';
//import Chip from '@mui/material/Chip';
//import Stack from '@mui/material/Stack';
//import DownloadIcon from '@mui/icons-material/Download';
//import TimelineContainer from "./timeline/TimelineContainer.js";
import ResponsiveGrid from '../subcomponents/ResponsiveGrid';

const ResourceList = ({}) => {
   
  const dispatch = useDispatch();
  //const students = useSelector(state => state[entityName].data);
  //const isLoading = useSelector(state => state[entityName].isLoading);
  //const error = useSelector(state => state[entityName].error);
 
  useEffect(() => {
    dispatch(fetchStudentsRequest());
  }, [dispatch]);

  /*const handleDelete = (studentId) => {
    dispatch(deleteStudentRequest(studentId));
  };*/

  return (
    <div>
      <Typography variant="h5">Activities</Typography>
      <ResponsiveGrid items ={4} />   
      <Typography variant="h5">PowerPoint</Typography>
      <ResponsiveGrid items ={4} /> 
      <Typography variant="h5">Online Resoures</Typography>
      <ResponsiveGrid items ={8} />  
      <Typography variant="h5">Manipulatives</Typography>
      <ResponsiveGrid items ={8} /> 
      <Typography variant="h5">How to...</Typography>
      <ResponsiveGrid items ={8} /> 
  
    </div>        
  );
};

export default ResourceList;
