// config.js
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
//import TimelineIcon from '@mui/icons-material/Timeline';
import WebIcon from '@mui/icons-material/Web';
//import UserIcon from '@mui/icons-material/VerifiedUser';
//import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import PersonIcon from '@mui/icons-material/Person';

const config = {
    version: "/v1",
    appName: "Edukentia",
    sideBar: [
      { label: "Dashboard", link: "/dashboard", icon: <DashboardIcon />, },
      { label: "Students", link: "/students", icon: <PersonIcon />, },
      { label: "Lesson Plans", link: "/lessons", icon: <SchoolIcon />, },
      { label: "Resources", link: "/resources", icon: <AssignmentIcon />, },
      { label: "Journal", link: "/journal", icon: <LibraryBooksIcon />, },
      { label: "Schedule", link: "/schedule", icon: <CalendarViewDayIcon />, },
      { label: "Webs", link: "/webs", icon: <WebIcon />, },
      { label: "Settings", link: "/settings", icon: <SettingsIcon />, },
      
      // Add more menu items as needed
    ],
    primaryHeader: [
        { label: "Home", link: "/" },
        { label: "Students", link: "/students" },
        { label: "Lesson Plans", link: "/lesson-plans" },
        // Add more menu items as needed
    ],
    primaryHeaderIcons: [
        { label: "Home", link: "/" },
        { label: "Students", link: "/students" },
        { label: "Lesson Plans", link: "/lesson-plans" },
        // Add more menu items as needed
    ],
    actions: [
        { icon: <EditIcon />, name: 'Edit' },
        { icon: <DeleteIcon />, name: 'Delete' },
        { icon: <SaveIcon />, name: 'Save' },
      ],

  };
  
  export default config;
  