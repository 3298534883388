import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchTimetablesSuccess, fetchTimetablesFailure, createTimetableSuccess, createTimetableFailure } from '../actions/timetableActions';
import { fetchTimetablesApi, createTimetableApi } from '../api/etl';

function* fetchTimetablesSaga() {
    try {
        const response = yield call(fetchTimetablesApi);
        yield put(fetchTimetablesSuccess(response));
    } catch (error) {
        yield put(fetchTimetablesFailure(error.message));
    }
}

function* createTimetableSaga(action) {
    try {
        const response = yield call(createTimetableApi, action.payload);
        yield put(createTimetableSuccess(response));
    } catch (error) {
        yield put(createTimetableFailure(error.message));
    }
}

export function* watchFetchTimetables() {
    yield takeLatest('FETCH_TIMETABLES_REQUEST', fetchTimetablesSaga);
}

export function* watchCreateTimetable() {
    yield takeLatest('CREATE_TIMETABLE_REQUEST', createTimetableSaga);
}

export default function* timetableSagas() {
    yield all([
        watchFetchTimetables(),
        watchCreateTimetable(),
    ]);
}
