import React from 'react';

const OverlappingCirclesWithImage = ({ imageUrl }) => (
  <svg
    width="500"
    height="500"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="circle-clip">
        <circle cx="70" cy="30" r="30" />
        <circle cx="40" cy="40" r="40" />
        <circle cx="60" cy="60" r="40" />
      </clipPath>
    </defs>

    <image
      href={imageUrl}
      width="100"
      height="100"
      clipPath="url(#circle-clip)"
      preserveAspectRatio="xMidYMid slice"
    />
  </svg>
);

export default OverlappingCirclesWithImage;
