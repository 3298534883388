import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';

import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import React from "react";

const TimelineCard = ({ date, subject, topic, lessonTitle, summary,icon}): any => {
    let selectedIcon;

    switch (icon) {
      case 'week':
        selectedIcon = <FreeBreakfastIcon />;
        break;
      case 'home':
        selectedIcon = <LocalLibraryIcon />;
        break;
      case 'lesson':
        selectedIcon = <LibraryBooksIcon />;
        break;
      default:
        selectedIcon = <SchoolIcon />; // You can provide a default icon or null for an unknown value
    }

    return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          {selectedIcon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography>{subject}</Typography>
        <Typography color="text.secondary">{topic}</Typography>
        <Typography color="text.secondary">{lessonTitle}</Typography>
        <Typography color="text.secondary">{summary}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineCard;