import axios from 'axios';
import { getAuthHeader } from './etl';  // Assuming getAuthHeader is defined in etl.js

const BASE_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

// Fetch all schools
export const fetchSchoolsApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/schools/`, { headers: getAuthHeader() });
    return response.data;
};

// Create a new school
export const createSchoolApi = async (schoolData) => {
    const response = await axios.post(`${BASE_URL}/api/schools/`, schoolData, { headers: getAuthHeader() });
    return response.data;
};

// Update an existing school
export const updateSchoolApi = async (schoolId, schoolData) => {
    const response = await axios.put(`${BASE_URL}/api/schools/${schoolId}/`, schoolData, { headers: getAuthHeader() });
    return response.data;
};

// Delete a school
export const deleteSchoolApi = async (schoolId) => {
    const response = await axios.delete(`${BASE_URL}/api/schools/${schoolId}/`, { headers: getAuthHeader() });
    return response.data;
};
