import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';

const JoinNewsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = () => {
    if (email) {
      // Simulate a subscription process (e.g., API call)
      setMessage('Thank you for subscribing!');
      setEmail(''); // Reset email field
    } else {
      setMessage('Please enter a valid email address.');
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
      <Typography variant="h4" align="center" color="primary" gutterBottom>
        Stay Updated
      </Typography>
      <Typography variant="body1" gutterBottom>
        Subscribe to receive the latest updates and news directly in your inbox.
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <TextField
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          type="email"
          sx={{ flex: 1, minWidth: '250px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubscribe}
          sx={{ height: '56px' }} // Match TextField's height
        >
          join
        </Button>
      </Box>
      {message && (
        <Typography
          variant="body2"
          color={message.includes('Thank') ? 'success.main' : 'error.main'}
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default JoinNewsletter;
