import React from 'react';
import { Paper, Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Paper elevation={3} style={{ padding: '25px', margin: 'auto', maxWidth: '900px' }}>
      
        <Typography variant="h3" gutterBottom>
          Edukentia Privacy Policy
        </Typography>

        <Typography variant="body1" paragraph>
          Edukentia values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, and share information about you when you use our platform.
        </Typography>

        <Typography variant="h4" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect personal data such as your name, email address, and any other information you provide when creating an account or interacting with the platform. We may also collect technical data like IP address, browser type, and usage information.
        </Typography>

        <Typography variant="h4" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Edukentia uses your information to provide, maintain, and improve the platform. We may use your email address to send you important updates, notices, or promotional content, but you can opt out of marketing communications at any time.
        </Typography>

        <Typography variant="h4" gutterBottom>
          3. Data Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal data. We may share information with third-party service providers to assist in providing the platform (e.g., hosting, analytics). We will also comply with legal obligations to disclose your information where required by law.
        </Typography>

        <Typography variant="h4" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h4" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal data. You may also have the right to object to or restrict certain processing of your data. To exercise your rights, please contact us at privacy@edukentia.com.
        </Typography>

        <Typography variant="h4" gutterBottom>
          6. Children&apos;s Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Edukentia complies with the Children&apos;s Online Privacy Protection Act (COPPA). We do not knowingly collect personal information from children under the age of 13 without parental consent.
        </Typography>

        <Typography variant="h4" gutterBottom>
          7. International Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          If you are accessing Edukentia from outside of our operational jurisdiction, your personal data may be transferred to, and processed in, countries other than the one in which you reside. We ensure that such transfers comply with data protection laws.
        </Typography>

        <Typography variant="h4" gutterBottom>
          8. Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Edukentia may update this Privacy Policy from time to time. We will notify you of any changes by updating the &quot;Last Updated&quot; date at the top of this page.
        </Typography>

        <Typography variant="h4" gutterBottom>
          9. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at privacy@edukentia.com.
        </Typography>
      
    </Paper>
  );
};

export default PrivacyPolicy;
