// Action Types
export const FETCH_LESSON_PLANS_REQUEST = 'FETCH_LESSON_PLANS_REQUEST';
export const FETCH_LESSON_PLANS_SUCCESS = 'FETCH_LESSON_PLANS_SUCCESS';
export const FETCH_LESSON_PLANS_FAILURE = 'FETCH_LESSON_PLANS_FAILURE';

export const CREATE_LESSON_PLAN_REQUEST = 'CREATE_LESSON_PLAN_REQUEST';
export const CREATE_LESSON_PLAN_SUCCESS = 'CREATE_LESSON_PLAN_SUCCESS';
export const CREATE_LESSON_PLAN_FAILURE = 'CREATE_LESSON_PLAN_FAILURE';

// Action Creators
export const fetchLessonPlansRequest = () => ({ type: FETCH_LESSON_PLANS_REQUEST });
export const fetchLessonPlansSuccess = (data) => ({ type: FETCH_LESSON_PLANS_SUCCESS, payload: data });
export const fetchLessonPlansFailure = (error) => ({ type: FETCH_LESSON_PLANS_FAILURE, payload: error });

export const createLessonPlanRequest = (lessonPlanData) => ({ type: CREATE_LESSON_PLAN_REQUEST, payload: lessonPlanData });
export const createLessonPlanSuccess = (lessonPlan) => ({ type: CREATE_LESSON_PLAN_SUCCESS, payload: lessonPlan });
export const createLessonPlanFailure = (error) => ({ type: CREATE_LESSON_PLAN_FAILURE, payload: error });

export const FETCH_LESSON_PLAN_DETAIL_REQUEST = 'FETCH_LESSON_PLAN_DETAIL_REQUEST';
export const FETCH_LESSON_PLAN_DETAIL_SUCCESS = 'FETCH_LESSON_PLAN_DETAIL_SUCCESS';
export const FETCH_LESSON_PLAN_DETAIL_FAILURE = 'FETCH_LESSON_PLAN_DETAIL_FAILURE';

export const fetchLessonPlanDetailRequest = (id) => ({
    type: FETCH_LESSON_PLAN_DETAIL_REQUEST,
    payload: id,
});

export const fetchLessonPlanDetailSuccess = (data) => ({
    type: FETCH_LESSON_PLAN_DETAIL_SUCCESS,
    payload: data,
});

export const fetchLessonPlanDetailFailure = (error) => ({
    type: FETCH_LESSON_PLAN_DETAIL_FAILURE,
    payload: error,
});
