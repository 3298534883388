import React from 'react';
import { Box, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LanguageIcon from '@mui/icons-material/Language';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import PeopleIcon from '@mui/icons-material/People';

const SDG4Alignment = () => {
  const sdgGoals = [
    {
      title: 'Inclusive Education',
      icon: <AccessibilityNewIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Ensuring quality, inclusive, and equitable learning environments for all students.',
    },
    {
      title: 'Lifelong Learning',
      icon: <SchoolIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Supporting continuous education from primary to advanced levels through accessible digital resources.',
    },
    {
      title: 'Global Citizenship',
      icon: <LanguageIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Empowering students to be active contributors in a globalized world through diverse curricula.',
    },
    {
      title: 'Safe Learning Environments',
      icon: <VerifiedUserIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Promoting secure, respectful, and empowering educational spaces with data-driven insights.',
    },
    {
      title: 'Sustainability Focus',
      icon: <EnergySavingsLeafIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Incorporating sustainable practices and promoting environmental awareness among young learners.',
    },
    {
      title: 'Equal Access',
      icon: <PeopleIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: 'Bridging the digital divide with tools that ensure access to education for underserved communities.',
    },
  ];

  return (
    <Box sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: 4,
        textAlign: 'center',
      
        '&::before': {
          content: '""',
          backgroundImage: 'url(./images/teachercircle.jpg)', // Add the path to your image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.3, // Control the opacity here
          position: 'absolute', 
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: -1,
        },
      }}>
      <Typography variant="h4" sx={{ marginBottom: 3, color: 'primary.main' }}>
        SDG4: Quality Education
      </Typography>

      <Grid container spacing={4}     justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h5" gutterBottom>
                    Edukentia aligns with the United Nations Sustainable Development Goal 4 by fostering an inclusive, equitable, and accessible educational environment for all.
                </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={8} >
            <Grid container spacing={4} alignItems="stretch" >
                
                {sdgGoals.map((goal, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                    sx={{
                        height: '100%',
                        backgroundColor: 'grey.100',
                        borderRadius: 2,
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': { transform: 'scale(1.05)' },
                    }}
                    elevation={3}
                    >
                    <CardContent>
                        <IconButton>{goal.icon}</IconButton>
                        <Typography variant="h6" gutterBottom>
                        {goal.title}
                        </Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: 'text.secondary' }}>
                        {goal.description}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SDG4Alignment;
