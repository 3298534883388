import React from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LibraryBooks as LibraryBooksIcon, Assignment as AssignmentIcon, School as SchoolIcon, Timeline as TimelineIcon, Web as WebIcon, Home as HomeIcon, Settings as SettingsIcon } from '@mui/icons-material';
import NavBar from '../NavBar';
import config from '../../config/app';

const api = config.version;
const app_name = config.appName;
const drawerWidth = 240;

const Root = styled('div')(() => ({
  display: 'flex',
}));

const DrawerContainer = styled(Drawer)(() => ({
  width: drawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const DashboardLayout = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <NavBar title={app_name} />
      <DrawerContainer
        variant="permanent"
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem button component={RouterLink} to={api +"/dashboard"}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/students"}>
              <ListItemIcon><SchoolIcon /></ListItemIcon>
              <ListItemText primary="Students" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/lesson-plans"}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Lesson Plans" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/resources"}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Resources" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/weekly-plans"}>
              <ListItemIcon><AssignmentIcon /></ListItemIcon>
              <ListItemText primary="Weekly Plans" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/journal"}>
              <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
              <ListItemText primary="Journal" />
            </ListItem>
            <ListItem button component={RouterLink} to="/v1/webs">
              <ListItemIcon><WebIcon /></ListItemIcon>
              <ListItemText primary="Webs" />
            </ListItem>
            <ListItem button component={RouterLink} to={api +"/analytics"}>
              <ListItemIcon><TimelineIcon /></ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
            <ListItem button component={RouterLink} to="/settings">
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Box>
      </DrawerContainer>
      <Content>
        <Toolbar />
        <Outlet />
      </Content>
    </Root>
  );
};

export default DashboardLayout;
