import React from 'react';
import { Parallax } from 'react-parallax';
import { Container, Grid, Typography, Box } from '@mui/material';
import aboutImage from '../../assets/ai.jpg'; // Update this path to your image location
//import aboutBackground from './images/scattered-forcefields.svg'; // Update this path to your image location
import OverlappingCirclesWithImage  from './OverlappingCirclesWithimages';
const About = () => {
  return (
    <Parallax
      bgImage={'images/scattered-forcefields_bg.svg'}
      bgImageAlt="About Background"
      strength={300}
    >
      <Box sx={{ padding: '100px 0', backgroundColor: 'rgba(256, 256, 256, 0.1)' }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            <Grid item xs={12} md={6}>
              <Typography variant="h4" color="primary" gutterBottom>
                What is Edukentia?
              </Typography>
              <Typography variant="h6" color="textPrimary" paragraph>
              Edukentia is an innovative platform that empowers educators by streamlining instruction planning with the use of AI technology, saving teachers time while enhancing classroom learning experiences. It facilitates collaboration through resource sharing and integrates with other platforms to improve communication among teachers, students, and parents. Edukentia also tracks student progress in real-time, providing insight on an individual basis. Join us in revolutionizing education with Edukentia—where planning meets innovation for a collaborative future of learning.</Typography>
             
            </Grid>

            {/* Image Section with Irregular Shape and Transparent Edges */}
            <Grid item xs={12} md={6}>
              <Box>
              <OverlappingCirclesWithImage imageUrl={aboutImage} />
         
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Parallax>
  );
};

export default About;
