import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.edukentia.com';

// Helper to get Authorization header
export const getAuthHeader = () => {
  const token = localStorage.getItem('authToken');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Helper to get API Key header
const getApiKeyHeader = () => {
  const apiKey = localStorage.getItem('apiKey');
  return apiKey ? { 'X-Api-Key': apiKey } : {};
};

// Combined headers for requests
const getHeaders = () => ({
  ...getAuthHeader(),
  ...getApiKeyHeader(),
  'Content-Type': 'application/json',
});

// User-related API functions
export const registerUser = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/users/`, data);
  return response.data;
};

export const signInUser = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/auth/`, data);
  localStorage.setItem('authToken', response.data.token);
  return response.data;
};

// CRUD operations for Students
export const fetchStudentsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/students/`, { headers: getHeaders() });
  return response.data;
};

export const addStudentApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/students/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteStudentApi = async (studentId) => {
  const response = await axios.delete(`${BASE_URL}/api/students/${studentId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Lesson Plans
export const fetchLessonPlansApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/lessonplans/`, { headers: getHeaders() });
  return response.data;
};

export const createLessonPlanApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/lessonplans/`, data, { headers: getHeaders() });
  return response.data;
};

export const fetchLessonPlanDetailApi = async (id) => {
  const response = await axios.get(`${BASE_URL}/api/lessonplans/${id}/`, { headers: getHeaders() });
  return response.data;
};

// Fetch Dashboard Data
export const fetchDashboardDataApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/dashboard-data/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Schools
export const fetchSchoolsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/schools/`, { headers: getHeaders() });
  return response.data;
};

export const addSchoolApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/schools/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateSchoolApi = async (schoolId, data) => {
  const response = await axios.put(`${BASE_URL}/api/schools/${schoolId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteSchoolApi = async (schoolId) => {
  const response = await axios.delete(`${BASE_URL}/api/schools/${schoolId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for School Districts
export const fetchSchoolDistrictsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/school-districts/`, { headers: getHeaders() });
  return response.data;
};

export const addSchoolDistrictApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/school-districts/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateSchoolDistrictApi = async (districtId, data) => {
  const response = await axios.put(`${BASE_URL}/api/school-districts/${districtId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteSchoolDistrictApi = async (districtId) => {
  const response = await axios.delete(`${BASE_URL}/api/school-districts/${districtId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Teachers
export const fetchTeachersApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/teachers/`, { headers: getHeaders() });
  return response.data;
};

export const addTeacherApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/teachers/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateTeacherApi = async (teacherId, data) => {
  const response = await axios.put(`${BASE_URL}/api/teachers/${teacherId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteTeacherApi = async (teacherId) => {
  const response = await axios.delete(`${BASE_URL}/api/teachers/${teacherId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Lessons
export const fetchLessonsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/lessons/`, { headers: getHeaders() });
  return response.data;
};

export const addLessonApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/lessons/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateLessonApi = async (lessonId, data) => {
  const response = await axios.put(`${BASE_URL}/api/lessons/${lessonId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteLessonApi = async (lessonId) => {
  const response = await axios.delete(`${BASE_URL}/api/lessons/${lessonId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Assessments
export const fetchAssessmentsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/assessments/`, { headers: getHeaders() });
  return response.data;
};

export const addAssessmentApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/assessments/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateAssessmentApi = async (assessmentId, data) => {
  const response = await axios.put(`${BASE_URL}/api/assessments/${assessmentId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteAssessmentApi = async (assessmentId) => {
  const response = await axios.delete(`${BASE_URL}/api/assessments/${assessmentId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Grades
export const fetchGradesApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/grades/`, { headers: getHeaders() });
  return response.data;
};

export const addGradeApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/grades/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateGradeApi = async (gradeId, data) => {
  const response = await axios.put(`${BASE_URL}/api/grades/${gradeId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteGradeApi = async (gradeId) => {
  const response = await axios.delete(`${BASE_URL}/api/grades/${gradeId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Notifications
export const fetchNotificationsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/notifications/`, { headers: getHeaders() });
  return response.data;
};

export const addNotificationApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/notifications/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateNotificationApi = async (notificationId, data) => {
  const response = await axios.put(`${BASE_URL}/api/notifications/${notificationId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteNotificationApi = async (notificationId) => {
  const response = await axios.delete(`${BASE_URL}/api/notifications/${notificationId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Alerts
export const fetchAlertsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/alerts/`, { headers: getHeaders() });
  return response.data;
};

export const addAlertApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/alerts/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateAlertApi = async (alertId, data) => {
  const response = await axios.put(`${BASE_URL}/api/alerts/${alertId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteAlertApi = async (alertId) => {
  const response = await axios.delete(`${BASE_URL}/api/alerts/${alertId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Term Reports
export const fetchTermReportsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/term-reports/`, { headers: getHeaders() });
  return response.data;
};

export const addTermReportApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/term-reports/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateTermReportApi = async (termReportId, data) => {
  const response = await axios.put(`${BASE_URL}/api/term-reports/${termReportId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteTermReportApi = async (termReportId) => {
  const response = await axios.delete(`${BASE_URL}/api/term-reports/${termReportId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Timetables
export const fetchTimetablesApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/timetables/`, { headers: getHeaders() });
  return response.data;
};

export const createTimetableApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/timetables/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateTimetableApi = async (timetableId, data) => {
  const response = await axios.put(`${BASE_URL}/api/timetables/${timetableId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteTimetableApi = async (timetableId) => {
  const response = await axios.delete(`${BASE_URL}/api/timetables/${timetableId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for TimeSlots
export const fetchTimeSlotsApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/time-slots/`, { headers: getHeaders() });
  return response.data;
};

export const addTimeSlotApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/time-slots/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateTimeSlotApi = async (timeSlotId, data) => {
  const response = await axios.put(`${BASE_URL}/api/time-slots/${timeSlotId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteTimeSlotApi = async (timeSlotId) => {
  const response = await axios.delete(`${BASE_URL}/api/time-slots/${timeSlotId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Curriculum
export const fetchCurriculumApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/curriculums/`, { headers: getHeaders() });
  return response.data;
};

export const addCurriculumApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/curriculums/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateCurriculumApi = async (curriculumId, data) => {
  const response = await axios.put(`${BASE_URL}/api/curriculums/${curriculumId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteCurriculumApi = async (curriculumId) => {
  const response = await axios.delete(`${BASE_URL}/api/curriculums/${curriculumId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Syllabus
export const fetchSyllabusApi = async () => {
  const response = await axios.get(`${BASE_URL}/api/syllabuses/`, { headers: getHeaders() });
  return response.data;
};

export const addSyllabusApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/api/syllabuses/`, data, { headers: getHeaders() });
  return response.data;
};

export const updateSyllabusApi = async (syllabusId, data) => {
  const response = await axios.put(`${BASE_URL}/api/syllabuses/${syllabusId}/`, data, { headers: getHeaders() });
  return response.data;
};

export const deleteSyllabusApi = async (syllabusId) => {
  const response = await axios.delete(`${BASE_URL}/api/syllabuses/${syllabusId}/`, { headers: getHeaders() });
  return response.data;
};

// CRUD operations for Topics
export const fetchTopicsApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/topics/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addTopicApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/topics/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateTopicApi = async (topicId, data) => {
    const response = await axios.put(`${BASE_URL}/api/topics/${topicId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteTopicApi = async (topicId) => {
    const response = await axios.delete(`${BASE_URL}/api/topics/${topicId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  // CRUD operations for Subtopics
  export const fetchSubtopicsApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/subtopics/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addSubtopicApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/subtopics/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateSubtopicApi = async (subtopicId, data) => {
    const response = await axios.put(`${BASE_URL}/api/subtopics/${subtopicId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteSubtopicApi = async (subtopicId) => {
    const response = await axios.delete(`${BASE_URL}/api/subtopics/${subtopicId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  // CRUD operations for Objectives
  export const fetchObjectivesApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/objectives/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addObjectiveApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/objectives/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateObjectiveApi = async (objectiveId, data) => {
    const response = await axios.put(`${BASE_URL}/api/objectives/${objectiveId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteObjectiveApi = async (objectiveId) => {
    const response = await axios.delete(`${BASE_URL}/api/objectives/${objectiveId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  // CRUD operations for CurriculumWeb
  export const fetchCurriculumWebsApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/curriculum-webs/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addCurriculumWebApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/curriculum-webs/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateCurriculumWebApi = async (curriculumWebId, data) => {
    const response = await axios.put(`${BASE_URL}/api/curriculum-webs/${curriculumWebId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteCurriculumWebApi = async (curriculumWebId) => {
    const response = await axios.delete(`${BASE_URL}/api/curriculum-webs/${curriculumWebId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  // CRUD operations for ThematicWeb
  export const fetchThematicWebsApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/thematic-webs/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addThematicWebApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/thematic-webs/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateThematicWebApi = async (thematicWebId, data) => {
    const response = await axios.put(`${BASE_URL}/api/thematic-webs/${thematicWebId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteThematicWebApi = async (thematicWebId) => {
    const response = await axios.delete(`${BASE_URL}/api/thematic-webs/${thematicWebId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  // CRUD operations for InstructionalPlan
  export const fetchInstructionalPlansApi = async () => {
    const response = await axios.get(`${BASE_URL}/api/instructional-plans/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const addInstructionalPlanApi = async (data) => {
    const response = await axios.post(`${BASE_URL}/api/instructional-plans/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const updateInstructionalPlanApi = async (instructionalPlanId, data) => {
    const response = await axios.put(`${BASE_URL}/api/instructional-plans/${instructionalPlanId}/`, data, { headers: getHeaders() });
    return response.data;
  };
  
  export const deleteInstructionalPlanApi = async (instructionalPlanId) => {
    const response = await axios.delete(`${BASE_URL}/api/instructional-plans/${instructionalPlanId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  

  export const fetchCurriculumWebApi = async (userId) => {
    const response = await axios.get(`${BASE_URL}/api/curriculumwebs/${userId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const fetchThematicWebApi = async (curriculumWebId) => {
    const response = await axios.get(`${BASE_URL}/api/thematicwebs/${curriculumWebId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  export const fetchWeeklyPlanApi = async (userId) => {
    const response = await axios.get(`${BASE_URL}/api/weekly-plans/${userId}/`, { headers: getHeaders() });
    return response.data;
  };
  
  //analytics
  export const fetchAnalyticsApi = async (id) => {
    const response = await axios.get(`${BASE_URL}/api/analytics/api/analytics/class-performance/${id}/`, { headers: getHeaders() });
    return response.data;
  }
  /*export const fetchLessonPlansApi = async (userId) => {
    const response = await axios.get(`${BASE_URL}/api/lesson-plans/${userId}/`, { headers: getHeaders() });
    return response.data;
  };*/